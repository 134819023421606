import "./DataExport.scss";

import { Box, Button, MenuItem, Switch, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import Papa from "papaparse";
import React from "react";
import { MasterIndex, SessionHandler } from "verdiapi";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { allParsedDataTypes } from "verditypes";
import { dayMs, minuteMs, secondMs } from "verditypes/dist/timeConstants";

import IconDatePicker from "../components/generic/IconDatePicker/IconDatePicker";
import FocusContext from "../services/mapManagement/FocusContext";

// Create an enum-like mapping for data type categories
const DataTypeCategory = {
    NDVI: "NDVI",
    SOIL_SENSOR: "Soil Sensor",
    SOIL_MOISTURE: "Soil Moisture",
    IRRIGATION_CONTROL: "Irrigation Control",
    WATER_FLOW: "Water Flow",
    IRRIGATION_PLANNING: "Irrigation Planning",
    DEVICE_DATA: "Device Data",
    ENVIRONMENTAL: "Environmental",
    PRESSURE_SENSOR: "Pressure Sensor",
    ADMIN_ONLY: "Admin Only",
};

// Create a mapping for user data types with their labels and categories
const USER_DATA_TYPES = {
    avgNDVI: { label: "Average NDVI", category: DataTypeCategory.NDVI },
    draginoEC: { label: "Dragino EC", category: DataTypeCategory.SOIL_SENSOR },
    moisture_watermark: { label: "Moisture Watermark", category: DataTypeCategory.SOIL_MOISTURE },
    moisture_irrometer: { label: "Moisture Irrometer", category: DataTypeCategory.SOIL_MOISTURE },
    moisture_vol: { label: "Volumetric Moisture", category: DataTypeCategory.SOIL_MOISTURE },
    moisture: { label: "Moisture", category: DataTypeCategory.SOIL_MOISTURE },
    ndviReading: { label: "NDVI Reading", category: DataTypeCategory.NDVI },
    ndviVariance: { label: "NDVI Variance", category: DataTypeCategory.NDVI },
    valveOpenPercent: { label: "Valve Open Percentage", category: DataTypeCategory.IRRIGATION_CONTROL },
    valveState: { label: "Valve State", category: DataTypeCategory.IRRIGATION_CONTROL },
    v1flowmeter: { label: "Flow Meter V1", category: DataTypeCategory.WATER_FLOW },
    zoneIrrigationScheduled: { label: "Zone Irrigation Scheduled", category: DataTypeCategory.IRRIGATION_PLANNING },
    saplingResetCount: { label: "Sapling Reset Count", category: DataTypeCategory.DEVICE_DATA },
    temperature: { label: "Temperature", category: DataTypeCategory.ENVIRONMENTAL },
    packetDrop: { label: "Packet Drop", category: DataTypeCategory.DEVICE_DATA },
    saplingBattery: { label: "Sapling Battery", category: DataTypeCategory.DEVICE_DATA },
    v1transducer: { label: "Transducer V1", category: DataTypeCategory.PRESSURE_SENSOR },
};

// Create a mapping for all data types with those not in USER_DATA_TYPES marked as admin-only
const ALL_DATA_TYPES = Object.fromEntries(
    allParsedDataTypes.map((dataType) => {
        if (USER_DATA_TYPES[dataType]) {
            return [dataType, USER_DATA_TYPES[dataType]];
        }
        return [dataType, { label: dataType, category: DataTypeCategory.ADMIN_ONLY }];
    }),
);

// Create a mapping from each category to an array of data type keys that belong to that category
// eslint-disable-next-line no-unused-vars
const categoryToDataTypeKeys = Object.entries(ALL_DATA_TYPES).reduce((acc, [key, value]) => {
    const { category } = value;
    if (!acc[category]) {
        acc[category] = [];
    }
    acc[category].push(key);
    return acc;
}, {});

const FILENAME_DATE_FORMAT = "yyyy-MM-dd";
const FILENAME_DATETIME_FORMAT = "yyyy-MM-dd_HH-mm-ss";
const DISPLAY_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

const TIMESTAMP_COLUMN = "Timestamp (local time)";

const SOURCE_TYPE_LABELS = {
    account: "Account",
    field: "Field",
    zone: "Zone",
    device: "Device",
};

export default class DataExport extends React.Component {
    constructor(props) {
        super(props);

        const now = new Date(Date.now());
        this.eodToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        this.state = {
            startDate: new Date(this.eodToday - dayMs * 7 + 1),
            endDate: this.eodToday,
            sourceType: "",
            sourceID: "",
            dataTypes: [],
            splitDataByPosition: false,
        };
    }

    componentDidMount() {
        console.info("DataExport: Added listener");
        SessionHandler.onSessionLoad.addListener(() => {
            console.info("DataExport: Updating CSV data page after timeout");
            setTimeout(() => {
                this.forceUpdate();
                console.info("DataExport: Updating CSV data page");
            }, secondMs);
        });
    }

    /**
     *
     * @param {String} sourceType
     * @param {String} sourceID
     * @param {Array} dataTypes
     *
     * @returns
     */
    async doDownload(sourceType, sourceID, dataTypes) {
        // If the start time is after the end time, return
        if (this.state.startDate.valueOf() > this.state.endDate.valueOf()) {
            return;
        }

        // Start the loading overlay
        FocusContext.startLoadHandler("Loading data...", "DataExport");
        console.info("DataExport: Loading data...");

        // Generate the database
        const db = generateDatabase(sourceType, sourceID, dataTypes, {
            splitDataByPosition: this.state.splitDataByPosition,
        });
        db.compressedRequests = true;
        db.debugTiming = true;

        // Start database cursor at start time
        let cursor = this.state.startDate;

        // Download data in 60 day chunks
        while (cursor.valueOf() < this.state.endDate.valueOf()) {
            // Move cursor forward 60 days or to the end time, whichever is smaller
            cursor = new Date(
                Math.min(
                    cursor.valueOf() + dayMs * 60, // 60 days
                    this.state.endDate.valueOf(),
                ),
            );

            console.info(`DataExport: Getting data from ${this.state.startDate.toString()} to ${cursor.toString()}`);

            // Get the data from the database (will ignore already downloaded data)
            // eslint-disable-next-line no-await-in-loop
            await db.getData(this.state.startDate, cursor);
        }

        console.info(`DataExport: Data has been loaded`);

        // Update the loading overlay
        FocusContext.updateLoadHandler("Exporting data...", "DataExport");
        console.info("DataExport: Exporting data...");

        // Parse the data needed for the CSV
        const { rows, columnOrder } = this.parseDataForCSV(db);

        // Get the source name
        let sourceName;
        switch (sourceType) {
            case "account":
                sourceName = SessionHandler.currentUserObject.username;
                break;
            case "field":
                sourceName = MasterIndex.aoi.byID[sourceID].name;
                break;
            case "zone":
                sourceName = MasterIndex.zone.byID[sourceID].name;
                break;
            case "device":
                if (MasterIndex.irrigationDevice.byID[sourceID]) {
                    sourceName = MasterIndex.irrigationDevice.byID[sourceID].name;
                } else if (MasterIndex.blockValve.byID[sourceID]) {
                    sourceName = MasterIndex.blockValve.byID[sourceID].name;
                } else if (MasterIndex.thirdPartyDevice.byID[sourceID]) {
                    sourceName = MasterIndex.thirdPartyDevice.byID[sourceID].name;
                } else {
                    sourceName = "unknown device name";
                }
                break;
            default:
                sourceName = "unknown source";
                break;
        }

        // Generate filename using the source name, date range, and current date
        const startDateFormatted = getTimeString(this.state.startDate, FILENAME_DATE_FORMAT);
        const endDateFormatted = getTimeString(this.state.endDate, FILENAME_DATE_FORMAT);
        const currentDateTimeFormatted = getTimeString(new Date(), FILENAME_DATETIME_FORMAT);
        const filename = `${sourceType}_'${sourceName}'_${startDateFormatted}_to_${endDateFormatted}_downloaded_at_${currentDateTimeFormatted}.csv`;

        // Update the loading overlay
        FocusContext.updateLoadHandler("Downloading data...", "DataExport");
        console.info("DataExport: Downloading data...");

        // Download the data
        exportToCsv(filename, rows, columnOrder);

        // End the loading overlay
        FocusContext.resolveLoadHandler("DataExport");
    }

    /**
     *
     * @param {HistoricalDataBase} db
     *
     * @returns
     */
    parseDataForCSV(db) {
        // Get the data keys from the database (unique column strings for source/dataType pairs)
        let dataKeys = Object.keys(db.dataKeyMetadataLookup);

        console.info(
            `DataExport: Parsing linked list with ${dataKeys.length} keys and ${db.data.value.length} data points`,
        );

        // Create an empty set to store the valid keys
        const validDataKeys = new Set();

        // Create empty objects to store the source id, source type, source name, zone name, and data type for each key
        const sourceIDsPerDataKey = {};
        const sourceTypesPerDataKey = {};
        const sourceNamesPerDataKey = {};
        const zoneNamesPerDataKey = {};
        const dataTypesPerDataKeys = {};

        // Create empty arrays to store the zone, device, and other keys
        const zoneKeys = [];
        const deviceKeys = [];
        const otherSourceKeys = [];

        // Iterate through the data keys
        dataKeys.forEach((k) => {
            // Get the source and zone info for the data key
            const sourceID = db.dataKeyMetadataLookup[k].source;
            let sourceName;
            let zoneName;

            // Set the source ID
            sourceIDsPerDataKey[k] = sourceID;

            // Add the key to the appropriate array and set the name and zone name

            // Zone source
            if (MasterIndex.zone.byID[sourceID]) {
                // Set the source and zone names
                sourceName = MasterIndex.zone.byID[sourceID].name;
                zoneName = sourceName;

                // Set the source type
                sourceTypesPerDataKey[k] = "zone";

                // Add the key to the zone rows
                zoneKeys.push(k);

                // Irrigation device source
            } else if (MasterIndex.irrigationDevice.byID[sourceID]) {
                // "moisture" dataType is invalid for irrigation devices
                if (db.dataKeyMetadataLookup[k].dataType === "moisture") {
                    console.warn(
                        `DataExport: Invalid column. dataType of "moisture" found for irrigation device: ${k}`,
                    );
                    return;
                }

                // Add the key to the device rows
                deviceKeys.push(k);

                // Set the source name
                sourceName = MasterIndex.irrigationDevice.byID[sourceID].name;

                // Set the source type
                sourceTypesPerDataKey[k] = "device";

                // Set the zone name if there are zones attached
                if (MasterIndex.irrigationDevice.byID[sourceID].zonesByValve) {
                    // Combine the names of all the zones attached to the device (filter out duplicates caused by single-valve devices)
                    zoneName = [
                        ...new Set(
                            MasterIndex.irrigationDevice.byID[sourceID].zonesByValve
                                .filter((z) => z !== null && z !== undefined)
                                .map((z) => z.name),
                        ),
                    ].join(" | ");
                }

                // Block valve source
            } else if (MasterIndex.blockValve.byID[sourceID]) {
                // "moisture" dataType is invalid for block valves
                if (db.dataKeyMetadataLookup[k].dataType === "moisture") {
                    console.warn(`DataExport: Invalid column. dataType of "moisture" found for block valve: ${k}`);
                    return;
                }

                // Add the key to the device rows
                deviceKeys.push(k);

                // Set the name
                sourceName = MasterIndex.blockValve.byID[sourceID].name;

                // Set the source type
                sourceTypesPerDataKey[k] = "device";

                try {
                    // Set the zone name if there are zones attached
                    if (MasterIndex.blockValve.byID[sourceID].connectedZones) {
                        // Combine the names of all the zones attached to the device
                        zoneName = [
                            ...new Set(
                                MasterIndex.blockValve.byID[sourceID].connectedZones
                                    .filter((z) => z !== null && z !== undefined)
                                    .map((z) => z.name),
                            ),
                        ].join(" | ");
                    }
                } catch (e) {
                    console.warn(`Failed to find name for connected zone for device ${sourceID}`);
                    zoneName = "Unknown Zone";
                }

                // Third party device source
            } else if (MasterIndex.thirdPartyDevice.byID[sourceID]) {
                // Add the key to the device rows
                deviceKeys.push(k);

                // Set the name
                sourceName = MasterIndex.thirdPartyDevice.byID[sourceID].name;

                // Set the source type
                sourceTypesPerDataKey[k] = "thirdPartyDevice";

                // Set the zone name if there are zones attached
                if (MasterIndex.thirdPartyDevice.byID[sourceID].connectedZones) {
                    // Combine the names of all the zones attached to the device
                    zoneName = MasterIndex.thirdPartyDevice.byID[sourceID].connectedZones
                        .filter((z) => z !== null && z !== undefined)
                        .map((z) => z.name)
                        .join(" | ");
                }

                // Other source with metadata available
            } else if (db.dataKeyMetadataLookup[k]) {
                // Add the key to the other rows
                otherSourceKeys.push(k);

                // Set the name
                sourceName = db.dataKeyMetadataLookup[k].source;

                // Set the zone name if there are zones attached
                if (db.dataKeyMetadataLookup[k].relevantZones) {
                    // Combine the names of all the zones attached to the device
                    zoneName = db.dataKeyMetadataLookup[k].relevantZones
                        .map((zid) => MasterIndex.zone.byID[zid].name)
                        .join(" | ");
                }

                // Other source
            } else if (db.dataKeys[k]?.relevantZones) {
                // Add the key to the other rows
                otherSourceKeys.push(k);

                // Set the name and zone name
                sourceName = "unknown name";
                zoneName = "unknown zone";
            } else {
                // Add the key to the other rows
                otherSourceKeys.push(k);

                // Set the name and zone name
                sourceName = "unknown name";
                zoneName = "unknown zone";
            }

            // Key is valid at this point
            validDataKeys.add(k);

            // Add the source name, zone name, and data type to the appropriate objects
            zoneNamesPerDataKey[k] = zoneName;
            sourceNamesPerDataKey[k] = sourceName;
            dataTypesPerDataKeys[k] = ALL_DATA_TYPES[db.dataKeyMetadataLookup[k].dataType].label;
        });

        // Add the valid keys to an array
        dataKeys = [...validDataKeys];

        // Merge the zone and device keys
        const zoneAndDeviceKeys = [...zoneKeys, ...deviceKeys];

        // Sort the merged keys by zone name, device name, and then data type with zones before devices
        zoneAndDeviceKeys.sort((a, b) => {
            // If the zone and source names are the same, sort by data type
            if (
                zoneNamesPerDataKey[a] === zoneNamesPerDataKey[b] &&
                sourceNamesPerDataKey[a] === sourceNamesPerDataKey[b]
            ) {
                return dataTypesPerDataKeys[a].localeCompare(dataTypesPerDataKeys[b], "en", { numeric: true });
            }

            // If the zone names are the same, place the zone key first and then sort by device name
            if (zoneNamesPerDataKey[a] === zoneNamesPerDataKey[b]) {
                // If 'a' is a zone, place it first
                if (zoneNamesPerDataKey[a] === sourceNamesPerDataKey[a]) {
                    return -1;
                }

                // If 'b' is a zone, place it first
                if (zoneNamesPerDataKey[b] === sourceNamesPerDataKey[b]) {
                    return 1;
                }

                // If neither 'a' nor 'b' is a zone, sort by device name
                return sourceNamesPerDataKey[a].localeCompare(sourceNamesPerDataKey[b], "en", { numeric: true });
            }

            // If the zone names are different, sort by zone name
            return zoneNamesPerDataKey[a].localeCompare(zoneNamesPerDataKey[b], "en", { numeric: true });
        });

        // Create an array to store the column headers
        let columnOrder = [TIMESTAMP_COLUMN];

        // Add the merged keys and other keys to the column headers
        columnOrder.push(...zoneAndDeviceKeys, ...otherSourceKeys);

        // Add the row labels (in the first column along with the ISO datetimes)
        sourceIDsPerDataKey[TIMESTAMP_COLUMN] = "Source ID";
        sourceTypesPerDataKey[TIMESTAMP_COLUMN] = "Source type";
        sourceNamesPerDataKey[TIMESTAMP_COLUMN] = "Source name";
        dataTypesPerDataKeys[TIMESTAMP_COLUMN] = "Data type";
        zoneNamesPerDataKey[TIMESTAMP_COLUMN] = "Zone name";

        // Create an empty array to store the data rows
        const dataRows = [];

        // Create a set to store the keys with non-zero values
        const keysWithNonZeroValues = new Set();

        // Create an empty object to store the most recent value for each key
        const mostRecentValuesForKeys = {};

        // Iterate through the data points in the database
        db.data.value.forEach((dataPoint) => {
            // Create a copy of the data point's data
            const dataRow = { ...dataPoint.data };

            // Get datetime from the data point
            const dateTime = dataPoint.date;

            // Iterate through the data keys
            dataKeys.forEach((k) => {
                // If the key exists in the data point's data, its value is now the most recent value for the key
                if (dataRow[k] !== null && dataRow[k] !== undefined) {
                    // If the key's value is non-zero, add it to the set of keys with non-zero values
                    if (dataRow[k] !== 0) {
                        keysWithNonZeroValues.add(k);
                    }

                    // Set the most recent value for the key
                    mostRecentValuesForKeys[k] = dataRow[k];

                    // If the key does not exist in the data point's data, set its value to the most recent value for the key
                } else if (mostRecentValuesForKeys[k] !== null && mostRecentValuesForKeys[k] !== undefined) {
                    dataRow[k] = mostRecentValuesForKeys[k];
                } else {
                    dataRow[k] = null;
                }
            });

            // Add the local date and time to the data
            dataRow[TIMESTAMP_COLUMN] = getTimeString(dateTime, DISPLAY_DATETIME_FORMAT);

            // Add the data row to the array of data rows
            dataRows.push(dataRow);
        });

        // Keep the keys with non-zero values and the valid keys
        const dataKeysToKeep = new Set([...validDataKeys, ...keysWithNonZeroValues, TIMESTAMP_COLUMN]);

        // Remove dataRows with keys not in the set of keys to keep
        dataRows.forEach((dataRow) => {
            Object.keys(dataRow).forEach((rowKey) => {
                if (!dataKeysToKeep.has(rowKey)) {
                    delete dataRow[rowKey];
                }
            });
        });

        // Remove columns with keys not in the set of keys to keep
        columnOrder = columnOrder.filter((columnKey) => dataKeysToKeep.has(columnKey));

        // Log the keys
        console.info("DataExport: All keys: ", dataKeys);
        console.info("DataExport: Keys with non-zero values: ", [...keysWithNonZeroValues]);
        console.info("DataExport: Valid keys: ", [...validDataKeys]);

        // Combine all the rows together
        const rows = [
            sourceIDsPerDataKey,
            sourceTypesPerDataKey,
            sourceNamesPerDataKey,
            dataTypesPerDataKeys,
            zoneNamesPerDataKey,
            ...dataRows,
        ];

        // Return the rows and column headers
        return {
            rows,
            columnOrder,
        };
    }

    render() {
        return (
            <div className={"exportContainer"}>
                <div className={"exportOptionContainer dateRangeContainer"}>
                    <Typography variant={"h4"}>Date range</Typography>

                    <Typography variant={"subtitle2"}>
                        From the beginning of the start date to the end of the end date in the current timezone
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        <strong>Current timezone:</strong> {`${this.timeZone}`}
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        <strong>Current datetime: </strong>
                        {`${getTimeString(new Date(Date.now()), DISPLAY_DATETIME_FORMAT)}`}
                    </Typography>

                    <div className={"dateRangeButtonsContainer"}>
                        {[
                            {
                                label: "Last Year",
                                getDates: () => {
                                    const now = new Date();
                                    return {
                                        startDate: new Date(now.getFullYear() - 1, 0, 1, 0, 0, 0, 0),
                                        endDate: new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999),
                                    };
                                },
                            },
                            {
                                label: "This Year",
                                getDates: () => {
                                    const now = new Date();
                                    return {
                                        startDate: new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0),
                                        endDate: new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate(),
                                            23,
                                            59,
                                            59,
                                            999,
                                        ),
                                    };
                                },
                            },
                            {
                                label: "Last Month",
                                getDates: () => {
                                    const now = new Date();
                                    const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
                                    const yearOfLastMonth =
                                        now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
                                    return {
                                        startDate: new Date(yearOfLastMonth, lastMonth, 1, 0, 0, 0, 0),
                                        endDate: new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999),
                                    };
                                },
                            },
                            {
                                label: "This Month",
                                getDates: () => {
                                    const now = new Date();
                                    return {
                                        startDate: new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0),
                                        endDate: new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate(),
                                            23,
                                            59,
                                            59,
                                            999,
                                        ),
                                    };
                                },
                            },
                            {
                                label: "Last Week",
                                getDates: () => {
                                    const now = new Date();
                                    const startOfWeek = now.getDate() - now.getDay();
                                    const startDate = new Date(now);
                                    startDate.setDate(startOfWeek - 7);
                                    const endDate = new Date(now);
                                    endDate.setDate(startOfWeek - 1);
                                    return {
                                        startDate: new Date(startDate.setHours(0, 0, 0, 0)),
                                        endDate: new Date(endDate.setHours(23, 59, 59, 999)),
                                    };
                                },
                            },
                            {
                                label: "This Week",
                                getDates: () => {
                                    const now = new Date();
                                    const startOfWeek = now.getDate() - now.getDay();
                                    const startDate = new Date(now);
                                    startDate.setDate(startOfWeek);
                                    return {
                                        startDate: new Date(startDate.setHours(0, 0, 0, 0)),
                                        endDate: new Date(now.setHours(23, 59, 59, 999)),
                                    };
                                },
                            },
                        ].map((dateRange) => (
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    const dates = dateRange.getDates();
                                    this.setState({
                                        startDate: dates.startDate,
                                        endDate: dates.endDate,
                                    });
                                }}
                                key={dateRange.label}
                            >
                                {dateRange.label}
                            </Button>
                        ))}
                    </div>

                    <div className={"startDateContainer"}>
                        <Typography variant={"h6"}>Start date</Typography>
                        <IconDatePicker
                            value={this.state.startDate}
                            onChange={(v) => {
                                this.setState({
                                    startDate: v,
                                });
                            }}
                            includeYear={true}
                        />
                    </div>

                    <div className={"endDateContainer"}>
                        <Typography variant={"h6"}>End date</Typography>
                        <IconDatePicker
                            value={this.state.endDate}
                            onChange={(v) => {
                                this.setState({
                                    endDate: v,
                                });
                            }}
                            includeYear={true}
                        />
                    </div>
                </div>

                <div className={"exportOptionContainer sourceSelectionContainer"}>
                    <Typography variant={"h4"}>Source</Typography>

                    <Typography variant={"subtitle2"}>Select the source to export data for</Typography>

                    <Typography variant={"subtitle2"}>
                        <strong>Note: </strong>Zones and devices within the specified source with no data for the
                        specified date range and data types will <strong>not</strong> be included in the output.
                    </Typography>

                    <div className={"sourceSelectionDropdownContainer"}>
                        <TextField
                            select
                            label={"Source type"}
                            onChange={(event) => {
                                this.setState({
                                    sourceType: event.target.value,
                                });
                            }}
                            style={{
                                minWidth: 200,
                            }}
                            className={"sourceTypeSelector"}
                        >
                            {Object.entries(SOURCE_TYPE_LABELS).map(([key, label]) => (
                                <MenuItem key={key} value={key}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            label={SOURCE_TYPE_LABELS[this.state.sourceType]}
                            onChange={(event) => {
                                this.setState({
                                    sourceID: event.target.value,
                                });
                            }}
                            style={{
                                minWidth: 200,
                            }}
                            className={"sourceSelector"}
                        >
                            {(() => {
                                switch (this.state.sourceType) {
                                    case "account":
                                        if (SessionHandler.currentUserObject) {
                                            return (
                                                <MenuItem
                                                    key={SessionHandler.currentUserObject.id}
                                                    value={SessionHandler.currentUserObject.id}
                                                >
                                                    {SessionHandler.currentUserObject.username}
                                                </MenuItem>
                                            );
                                        }
                                        return null;

                                    case "field":
                                        return MasterIndex.aoi.all
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((aoi) => (
                                                <MenuItem key={aoi.id} value={aoi.id}>
                                                    {aoi.name}
                                                </MenuItem>
                                            ));
                                    case "zone":
                                        return MasterIndex.zone.all
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((zone) => (
                                                <MenuItem key={zone.id} value={zone.id}>
                                                    {zone.name}
                                                </MenuItem>
                                            ));
                                    case "device":
                                        return [
                                            ...MasterIndex.irrigationDevice.all,
                                            ...MasterIndex.blockValve.all,
                                            ...MasterIndex.thirdPartyDevice.all,
                                        ]
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((device) => (
                                                <MenuItem key={device.id} value={device.id}>
                                                    {device.name + (device.id !== device.name ? ` (${device.id})` : "")}
                                                </MenuItem>
                                            ));

                                    default:
                                        return null;
                                }
                            })()}
                        </TextField>
                    </div>
                </div>

                <div className={"exportOptionContainer dataTypeSelectionContainer"}>
                    <Typography variant={"h4"}>Data types</Typography>

                    <Typography variant={"subtitle2"}>Select the data types to export</Typography>

                    <div className={"dataTypeSelectionButtonsContainer"}>
                        <Button
                            variant={"contained"}
                            onClick={() => {
                                this.setState({
                                    dataTypes: SessionHandler.admin
                                        ? Object.keys(ALL_DATA_TYPES)
                                        : Object.keys(USER_DATA_TYPES),
                                });
                            }}
                        >
                            Select all
                        </Button>

                        <Button
                            variant={"contained"}
                            onClick={() => {
                                this.setState({
                                    dataTypes: [],
                                });
                            }}
                        >
                            Deselect all
                        </Button>
                    </div>

                    <div className={"dataTypeSelectionDropdownContainer"}>
                        <FormControl>
                            <InputLabel id={"dataTypeSelectorLabel"}>Data types</InputLabel>
                            <Select
                                select
                                labelId={"dataTypeSelectorLabel"}
                                label={"Data types"}
                                onChange={(event) => {
                                    this.setState({
                                        dataTypes: event.target.value,
                                    });
                                }}
                                style={{
                                    minWidth: 200,
                                }}
                                className={"dataTypeSelector"}
                                value={this.state.dataTypes}
                                renderValue={(selected) => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={ALL_DATA_TYPES[value].label} />
                                        ))}
                                    </Box>
                                )}
                                multiple
                            >
                                {SessionHandler.admin &&
                                    Object.entries(ALL_DATA_TYPES).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value.label}
                                        </MenuItem>
                                    ))}
                                {!SessionHandler.admin &&
                                    Object.entries(USER_DATA_TYPES).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className={"exportOptionContainer splitOptionsByPosition"}>
                    <Typography variant={"h4"}>Split data by position</Typography>

                    <Switch
                        checked={this.state.splitDataByPosition}
                        onChange={(e) => {
                            this.setState({
                                splitDataByPosition: !this.state.splitDataByPosition,
                            });
                        }}
                    />
                </div>

                <div className={"exportButtonContainer"}>
                    <Button
                        variant={"contained"}
                        onClick={() => {
                            this.doDownload(this.state.sourceType, this.state.sourceID, this.state.dataTypes);
                        }}
                    >
                        Export data
                    </Button>
                </div>
            </div>
        );
    }
}

/**
 *
 * @param {string} filename
 * @param {Array} rows
 * @param {Array} columnOrder
 *
 * @returns
 */
function exportToCsv(filename, rows, columnOrder) {
    // Start the export timer
    const exportStartTime = Date.now();

    // Convert the data to CSV
    console.info(`DataExport: Exporting data to CSV... `);
    const csv = Papa.unparse(rows, {
        columns: columnOrder,
    });
    console.info(
        `DataExport: Exported data to CSV with ${rows.length} rows in ${(Date.now() - exportStartTime) / secondMs} seconds`,
    );

    // Create a blob from the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Download the blob

    // IE 10+
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);

        // Browsers that support HTML5 download attribute
    } else {
        // Create a link element
        const link = document.createElement("a");

        // Check if the link element supports the download attribute
        if (link.download !== undefined) {
            // Start the object URL timer
            const createObjectStartTime = Date.now();
            console.info(`DataExport: Creating object URL`);

            // Create an object URL from the blob
            const url = URL.createObjectURL(blob);
            console.info(
                `DataExport: Created object URL in ${(Date.now() - createObjectStartTime) / secondMs} seconds`,
            );

            // Set the link element's attributes
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";

            // Add, click, and then remove the link element from the document body
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

/**
 *
 * @param {String} sourceType
 * @param {String} sourceID
 * @param {Array} dataTypes
 *
 * @returns {HistoricalDataBase}
 */
function generateDatabase(sourceType, sourceID, dataTypes = undefined, { splitDataByPosition = false } = {}) {
    // Define arrays to store the sources and relevantZones to get data for
    let sourceIDs;
    let relevantZoneIDs;

    // Add sources and relevantZones based on the source type
    switch (sourceType) {
        case "account":
            // Do nothing, all sources and relevantZones will be included
            break;
        case "field":
            // Set relevant zones to the AOI's zones
            relevantZoneIDs = MasterIndex.aoi.byID[sourceID].zonesInArea.map((z) => z.id);
            break;
        case "zone":
            // Set relevant zones to the zone
            relevantZoneIDs = [sourceID];
            break;
        case "device":
            // Set sources to the device
            sourceIDs = [sourceID];
            break;
        default:
            break;
    }

    // Create the database with the specified sources, data types, and relevant zones
    const database = new HistoricalDataBase(sourceIDs, dataTypes || USER_DATA_TYPES, minuteMs * 3, {
        relevantZoneIDs: relevantZoneIDs,
        worryAboutPosition: splitDataByPosition,
        worryAboutRelevantZones: true,
    });

    // Return the database
    return database;
}
