import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Divider, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SessionHandler } from "verdiapi";

import { IconButtonWithTooltip } from "../../components/generic/IconButtonWithTooltip";
import { Tag } from "../../components/generic/Tag/Tag";
import { TAG } from "../../components/generic/Tag/types";
import { DesktopAdminSettingsNavList, DesktopUserSettingsNavList } from "./NavLinks";

/**
 * Settings menu
 * A gear icon which behaves differently for different users
 * - non-admins: directs to the settings page
 * - admins: opens a dropdown menu with the settings options (including going to settings page)
 */
export function SettingsMenu({ navBarHeight }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [badgeStyles, setBadgeStyles] = React.useState({});

    const { t } = useTranslation("modules");

    const isAdmin = Boolean(SessionHandler.admin);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const baseStylesPerHeight = {
            35: {
                top: 2,
                right: -7,
                fontSize: 6,
                borderRadius: "7px",
            },
            45: {
                top: 2,
                right: -8,
                fontSize: 6,
                borderRadius: "7px",
            },
            55: {
                top: 4,
                right: -12,
                fontSize: 9,
                marginTop: "2px",
                padding: "5px",
            },
        };

        setBadgeStyles(baseStylesPerHeight[navBarHeight]);
    }, [navBarHeight]);

    return (
        <>
            <IconButtonWithTooltip
                badgeContent={
                    <Tag
                        wrapperSx={{
                            height: "unset",
                            padding: badgeStyles.padding,
                            borderRadius: badgeStyles.borderRadius,
                        }}
                        sx={{
                            fontSize: badgeStyles.fontSize,
                            marginTop: badgeStyles.marginTop,
                        }}
                        tagVariant={TAG.NEW}
                    >
                        NEW
                    </Tag>
                }
                badgeSx={{
                    top: badgeStyles.top,
                    right: badgeStyles.right,
                }}
                onClick={handleOpen}
                icon={<SettingsIcon />}
                title={t("navigation.settingsMenu.settings")}
            />
            <Menu anchorEl={anchorEl} keepMounted style={{ zIndex: 1400 }} open={open} onClose={handleClose}>
                {DesktopUserSettingsNavList.map((navItem) => (
                    <MenuItem
                        key={`SettingsMenu_${navItem.url}`}
                        component={Link}
                        to={navItem.url}
                        onClick={handleClose}
                    >
                        {navItem.name}
                        {navItem.tagVariant && <Tag wrapperSx={{ marginLeft: 2 }} tagVariant={navItem.tagVariant} />}
                    </MenuItem>
                ))}
                {isAdmin && (
                    <Box>
                        <Divider />
                        <Typography sx={{ paddingLeft: 2, paddingTop: 2 }} variant={"subtitle2"}>
                            Admin Settings
                        </Typography>
                        {DesktopAdminSettingsNavList.map((navItem) => (
                            <MenuItem
                                key={`SettingsMenu_${navItem.url}`}
                                component={Link}
                                to={navItem.url}
                                onClick={handleClose}
                            >
                                {navItem.name}
                            </MenuItem>
                        ))}
                    </Box>
                )}
            </Menu>
        </>
    );
}
