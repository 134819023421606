import "./DeviceInfoCard.scss";

import { useSnackbar, withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import { POLLING_FREQUENCIES, refreshChangedDevices } from "../../../../services/devicePollingService";
import { useDevicePolling } from "../../../../services/devicePollingService/useDevicePolling";
import GlobalOptions from "../../../../utils/GlobalOptions";
import { FunctionalInfoCardEffect } from "../FunctionalInfoCard";
import { INFO_CARD_TYPES } from "../InfoCardTypes";
import { DeviceInfoCardBody } from "./DeviceInfoCardBody";
import { DeviceInfoCardHeader } from "./DeviceInfoCardHeader";
import { DeviceSubheader } from "./DeviceSubheader";

export const _FunctionalDeviceInfoCard = React.memo(({ Target, mapEntity }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [visibleState, setVisibleState] = useState(false);

    useDevicePolling({
        pollingFrequency: visibleState === "visible" ? POLLING_FREQUENCIES.INFOCARD_OPEN : null,
    });

    // Refresh device immediately when card becomes visible, ignoring the polling frequency
    useEffect(() => {
        if (visibleState === "visible") {
            refreshChangedDevices({
                includeSpecificDeviceID: Target.node_id,
            });
        }
    }, [visibleState]);

    let name = `Irrigation Device ${Target.id}`;
    if (Target.name) {
        name = Target.name;
    }
    let statusText = "idle";
    if (Target?.type) {
        statusText = DeviceConfigurationsByType[Target?.type]?.friendlyNameShort || statusText;
    }
    if (Array.isArray(Target?.valveStates) && Target.valveStates.includes("open")) {
        statusText = "irrigating";
    }
    let warning = false;
    if (Target.MapEntity && Target.MapEntity.warningText) {
        statusText = Target.MapEntity.warningText;
        warning = Target.MapEntity.warningToShow || Target.MapEntity.model.warnings[0];
    }
    if (!mapEntity) {
        mapEntity = Target.MapEntity;
    }

    return (
        <FunctionalInfoCardEffect
            currentInfoCardType={INFO_CARD_TYPES.DEVICE}
            mapEntity={mapEntity}
            Target={Target}
            key={`TempKey-${Target.id}`}
            title={
                <DeviceInfoCardHeader
                    Target={Target}
                    statusText={statusText}
                    warning={warning}
                    enqueueSnackbar={enqueueSnackbar}
                />
            }
            subheader={<DeviceSubheader Target={Target} />}
            name={name}
            duckable={!GlobalOptions.autoOpenZoneCards}
            contentComponent={DeviceInfoCardBody}
            minHeightExpression={() => "300px"}
            onChangeVisibleState={(newState) => {
                setVisibleState(newState);
            }}
        />
    );
});
export const FunctionalDeviceInfoCard = withSnackbar(_FunctionalDeviceInfoCard);
